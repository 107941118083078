import React from 'react';
import styled from 'styled-components';

function PastDownloads({ downloadDates }) {
  const tableData = downloadDates.map(dd => {
    const from = dd[1];
    const to = dd[2];
    return (
      <tr key={dd[0]}>
        <Td>{from}</Td>
        <Td>{to}</Td>
      </tr>
    );
  });
  const dateTable = (
    <Table border="1">
      <tbody>{tableData}</tbody>
    </Table>
  );

  return (
    <>
      <h2>Last 10 downloads</h2>
      {dateTable}
    </>
  );
}

const Table = styled.table`
  border: 1px solid;
`;
const Td = styled.td`
  border: 1px solid;
  padding: 2px;
`;

export default PastDownloads;
