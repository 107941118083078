import React from 'react';
import styled from 'styled-components';

function ErrorPopup({ errorBody, setError, setErrorBody }) {
  return (
    <Conteiner
      onClick={() => {
        setError(false);
        setErrorBody({});
      }}
    >
      <Error>
        <p>{errorBody.message}</p>
        <p>
          {errorBody.status ? `Status: ${errorBody.status}` : ''}{' '}
          {errorBody.code ? `Code: ${errorBody.code}` : ''}
        </p>
      </Error>
    </Conteiner>
  );
}

const Conteiner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(128, 128, 128, 0.95);
`;

const Error = styled.div`
  max-width: 600px;
  padding: 15px;
  margin: auto;
  border: solid red 10px;
  background-color: white;
`;

export default ErrorPopup;
