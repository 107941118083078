import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import './App.css';

import ErrorPopup from './components/error';
import Report from './components/report';
import PastDownloads from './components/past-downloads';

function App() {
  const [error, setError] = useState();
  const [errorBody, setErrorBody] = useState({});

  const [loading, setLoading] = useState();

  const [showReport, setShowReport] = useState();

  const [downloadDates, setDownloadDates] = useState([]);
  const [reportStartDate, setReportStartDate] = useState();

  useEffect(() => {
    (async function getData() {
      try {
        setLoading(true);
        const responce = await fetch('/previousDownloads');
        const json = await responce.json();
        if (json.error) {
          setError(true);
          setErrorBody(json.body);
          setLoading(false);
          return;
        }
        if (json.length) {
          setDownloadDates(json);
          const startDateString = json[0][2];
          const startDate = moment(startDateString, 'MM-DD-YYYYThh-mm-AZZ');
          setReportStartDate(startDate);
        }
        setLoading(false);
        setError(false);
        setErrorBody(false);
        setShowReport(true);
      } catch (err) {
        setError(true);
        setErrorBody(err);
        setLoading(false);
      }
    })();
  }, []);

  // Report download
  if (showReport) {
    return (
      <Conteiner>
        {error ? (
          <ErrorPopup errorBody={errorBody} setError={setError} setErrorBody={setErrorBody} />
        ) : (
          ''
        )}
        {loading ? <Loading>Loading...</Loading> : ''}
        <Report
          reportStartDate={reportStartDate}
          setLoading={setLoading}
          setError={setError}
          setErrorBody={setErrorBody}
        />
        <PastDownloads downloadDates={downloadDates} />
      </Conteiner>
    );
  }

  return (
    <Conteiner>
      {error && (
        <ErrorPopup errorBody={errorBody} setError={setError} setErrorBody={setErrorBody} />
      )}
      {loading && <Loading>Loading...</Loading>}
    </Conteiner>
  );
}

export default App;

// Loading
const Loading = styled.div`
  position: absolute;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(128, 128, 128, 0.8);
`;

const Conteiner = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
